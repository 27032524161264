export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: 'f69bb773-2f59-4b48-a5dc-821a0d40e9a3',
  },
  BACKEND_URL: 'https://stellargpt-eur.ab-inbev.com/stellar',
  GATEWAY_URL: 'https://stellargpt-eur.ab-inbev.com/genai',
  APPLICATION_ID: 'e8913a93-05c1-49fe-8476-27a09f6edfbd',
  PERSONAL_RAG_CONNECTOR_ID: '882acc17-6ab7-4940-9358-8cc47c4a0414',
  APIM_SUBSCRIPTION_KEY: '',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: '',
    CONNECTION_STRING: '',
  },
  APPLICATION_LABEL: 'Stellar',
  LAYOUT: {
    PERSONAL_RAG: false,
    CONTEXT_SELECTOR: false,
  },
};
